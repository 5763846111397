import { InjectionToken, makeEnvironmentProviders, inject } from '@angular/core';
const tooltipVariation = {
  theme: undefined,
  arrow: false,
  animation: 'scale',
  trigger: 'mouseenter',
  offset: [0, 5]
};
const popperVariation = {
  theme: 'light',
  arrow: true,
  offset: [0, 10],
  animation: undefined,
  trigger: 'click',
  interactive: true
};
function withContextMenuVariation(baseVariation) {
  return {
    ...baseVariation,
    placement: 'right-start',
    trigger: 'manual',
    arrow: false,
    offset: [0, 0]
  };
}
const TIPPY_LOADER = new InjectionToken('TIPPY_LOADER');
const TIPPY_REF = /* @__PURE__ */new InjectionToken('TIPPY_REF');
const TIPPY_CONFIG = new InjectionToken('Tippy config');
function provideTippyLoader(loader) {
  return makeEnvironmentProviders([{
    provide: TIPPY_LOADER,
    useValue: loader
  }]);
}
function provideTippyConfig(config) {
  return {
    provide: TIPPY_CONFIG,
    useValue: config
  };
}
function injectTippyRef() {
  const instance = inject(TIPPY_REF, {
    optional: true
  });
  if (instance) {
    return instance;
  }
  throw new Error('tp is not provided in the current context or on one of its ancestors');
}

/**
 * Generated bundle index. Do not edit.
 */

export { TIPPY_CONFIG, TIPPY_LOADER, TIPPY_REF, injectTippyRef, popperVariation, provideTippyConfig, provideTippyLoader, tooltipVariation, withContextMenuVariation };
